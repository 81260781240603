<template>
  <!-- footer -->
  <footer class="footer-site">
    <div class="container">
      <div class="footer-site-inner text-center">
        <p class="txt-footer-1">睡眠時呼吸音測定アプリ ズーメーター</p>
        <div class="logo-footer mb-4">
          <img src="images/logo-footer.png" alt="" />
          <span>Zzz Meter</span>
        </div>
        <p class="txt-footer-2 mb-3 mb-md-2">アプリのダウンロードはこちらから</p>
        <div class="download-app">
          <a href="https://apps.apple.com/jp/app/zzz-meter/id6446402422" target="_blank">
            <img src="images/app-store.png" alt="" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.zens.nerusoku.app" target="_blank">
            <img src="images/google-play.png" alt="" />
          </a>
        </div>
        <ul class="d-flex justify-content-center flex-wrap list-unstyled mb-3 nav-footer">
          <li><a href="/privacy-policy">プライバシーポリシー</a></li>
          <li><a href="/terms">利用規約</a></li>
          <li><a href="/contact">お問い合わせ</a></li>
        </ul>
        <div class="copyright">©2023 Okuchy,Inc. All rights reserved.</div>
      </div>
    </div>
  </footer>
  <!-- e: footer -->
</template>

<script>
export default {
  name: 'c-footer2',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
