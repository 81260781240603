import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Thanks from '../views/Thanks'
import Terms from '../views/Terms'
import PrivacyPolicy from '../views/PrivacyPolicy'
import Contact from '../views/Contact'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/thanks',
      component: Thanks
    },
    {
      path: '/terms',
      component: Terms
    },
    {
      path: '/privacy-policy',
      component: PrivacyPolicy
    },
    {
      path: '/contact',
      component: Contact
    },
  ]
})

export default router