<template>
<!-- header -->
	<header class="header-site">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" href="/">
          <span class="brand-img">
            <img src="images/logo.png" alt="ZzzMeter" />
          </span>
          <span class="brand-text">ZzzMeter</span>
        </a>
      </div>
    </nav>
	</header>
<!-- e: header -->
</template>

<script>
export default {
  name: 'c-header2',
}
</script>