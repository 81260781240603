<template>
  <Header/>
    <!-- main-content -->
    <main class="main-content">
      <!-- section-banner -->
      <section class="section-banner form-floating">
        <!-- <div class="banner-media">
          <video src="videos/zzzmeter_movie.mp4" loop muted autoplay playsinline></video>
        </div> -->
        <div class="container">
          <div class="wrap-1070 mx-auto">
            <div class="row align-items-center">
              <div class="col-lg-5 order-lg-last">
                <div class="banner-media">
                  <img src="videos/ZzzMeter_LP_top.gif" alt=""/>
                  <!-- <video src="videos/zzzmeter_movie.mp4" loop muted autoplay playsinline></video> -->
                </div>
              </div>
              <div class="col-lg-7">
                <div class="banner-content">
                  <h2 class="title title-type-1">睡眠中、<br/>しっかり呼吸<br/>できていますか？</h2>
                  <p class="banner-text">睡眠時呼吸音測定アプリ ズーメーター</p>
                  <div class="logo-zzzmeter">
                    <span class="logo-img">
                      <img src="images/logo_84x84.png" alt="..." />
                    </span>
                    <span class="logo-text">Zzz Meter</span>
                  </div>
                  <div class="download-app lg">
                    <a href="https://apps.apple.com/jp/app/zzz-meter/id6446402422" target="_blank">
                      <img src="images/app-store-lg.png" class="img-fluid" alt=""/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.zens.nerusoku.app" target="_blank">
                      <img src="images/google-play-lg.png" class="img-fluid" alt=""/>
                    </a>
                  </div>
                  <p class="banner-notice">※本品は健康管理を目的としたものであり、医療機器ではありません。</p>
                </div>
                <div class="img-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- e: section-banner -->

      <!-- section-intro -->
      <section class="section-intro form-floating">
        <div class="container">
          <div class="wrap-1070 mx-auto">
            <div class="row align-items-center">
              <div class="col-lg-6 order-lg-last">
                <div class="banner-media">
                  <img src="videos/ZzzMeter_LP_2edview.gif" alt=""/>
                  <!-- <video src="videos/zzzmeter_movie.mp4" loop muted autoplay playsinline></video> -->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="section-text">
                  <h3 class="title title-type-3">睡眠時の呼吸音から<br/>呼吸していないと<br/>思われる区間・回数<br/>を可視化</h3>
                  <div class="text-none">ZzzMeterは、睡眠中の呼吸音を録音したデータから呼吸をしていないと思われる時間が空白で可視化され、1時間あたりの呼吸をしていないと思われる平均回数や最長時間がわかります。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- e: section-intro -->

      <!-- section-issues -->
      <section id="problems" ref="problems" class="section-issues">
        <div class="container">
          <div class="wrap-1070 mx-auto">
            <div id="c-problem" class="problem-container">
              <h3 class="title title-type-1 text-center">こんなお悩みありませんか？</h3>
              <div class="problems">
                <div class="problem fadeIn wow" style="animation-delay: 0.2s;">
                  <div class="wow shake bubble">
                    <div class="problem-text">周囲から<br/><span>いびき</span><br/>を指摘される</div>
                  </div>
                </div> 
                <div class="problem fadeIn wow" style="animation-delay: 0.8s;">
                  <div class="wow shake bubble">
                    <div class="problem-text">睡眠時に<br/><span>呼吸</span>を<span><br/>していない</span><br/>と指摘される</div>
                  </div>
                </div>
                <div class="problem fadeIn wow" style="animation-delay: 0.4s;">
                  <div class="wow shake bubble">
                    <div class="problem-text">日中に強い<br/><span>眠気</span><br/>を感じる</div>
                  </div>
                </div>
                <div class="problem fadeIn wow" style="animation-delay: 0.6s;">
                  <div class="wow shake bubble">
                    <div class="problem-text">会議中に<br/><span>居眠り</span><br/>してしまう</div>
                  </div>
                </div>
                <div class="problem fadeIn wow" style="animation-delay: 1.0s;">
                  <div class="wow shake bubble">
                    <div class="problem-text">一日中<br/><span>だるさ</span><br/>を感じる</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- e: section-intro -->          
      
      <!-- section-issues -->
      <section class="section-issues">
        <div class="container">
          <div class="wrap-1070 mx-auto block-content bg-black-3">
            <h2 class="title-block d-lg-none">この悩み<br/>実はこんなに<br/>身近です</h2>
            <div class="row align-items-center">
              <div class="col-lg-5 col-chart order-lg-last">
                <div id="chart" class="chart-container mx-auto mx-lg-0 mb-3 mb-lg-0"></div>
              </div><!--/.col-->
              <div class="col-lg-7 col-text">
                <h2 class="title-block d-none d-lg-block">この悩み<br/>実はこんなに<br/>身近です</h2>
                <div class="desc">
                  <div class="wrap-440 mb-4 pb-lg-3">
                    当社が実施した研究によると、ある企業では睡眠時の呼吸音から「呼吸をしていないと思われる状態」が15回以上の人は、 測定対象者116名のうち70名(60%)いました。
                  </div>
                  <ul class="notices list-unstyled">
                    <li>*ここでいう回数とは、1時間あたりの「呼吸をしていないと思われる状態」の平均回数です。</li>
                    <li>*「呼吸をしていないと思われる状態」が10秒以上発生している場合に1回と数えています。</li>
                  </ul>
                </div>
              </div><!--/.col-->
            </div>
          </div><!--/.block-content-->
        </div>
      </section>
      <!-- e: section-issues -->

      <!-- section-issues -->
      <section class="section-issues">
        <div class="container">
          <div class="wrap-1070 mx-auto block-content bg-black-3">
            <div class="row">
              <div class="col-lg-5 col-sm">
                <h2 class="title-block">これって<br/> 呼吸が問題かも？</h2>                    
              </div><!--/.col-->
              <div class="col-lg-7 col-lg pt-2 pt-lg-0">
                <div class="desc pt-lg-2 mt-lg-1">
                  <p class="mb-3 mb-md-4 fz-18">睡眠中にいびきや「呼吸をしていないと思われる状態」が発生すると、</p>
                  <div class="list-circle-text">
                    <div class="circle-text wow fadeInUp" style="animation-delay: .1s;"><span>睡眠の質が低下</span></div>
                    <div class="circle-text wow fadeInUp" style="animation-delay: .2s;"><span>目覚めの悪さ</span></div>
                    <div class="circle-text wow fadeInUp" style="animation-delay: .3s;"><span>日中の眠気</span></div>
                    <div class="circle-text wow fadeInUp" style="animation-delay: .4s;"><span>イライラ</span></div>
                  </div>
                  <p class="note-2">
                    さらに状態が悪くなると、うつや生活習慣病、睡眠時無呼吸症候群などの疾患につながるリスクが生じてしまいます。
                  </p>
                </div>
              </div><!--/.col-->
            </div>
          </div><!--/.block-content-->
        </div>
      </section>
      <!-- e: section-issues -->

      <!-- section-easy-to-use -->
      <section id="advertisement" ref="advertisement" class="section-easy-to-use">
        <div class="container">
          <div class="wrap-1070 mx-auto block-content pb-0 bg-black-3 mb-0">
            <div class="heading text-center">
              <h2 class="title-block-big">ZzzMeterでカンタン測定</h2>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-6 text-center mb-4 mb-lg-0">
                <img src="videos/ZzzMeter_LP_about.gif" alt=""/>
                <!-- <video src="videos/zzzmeter_movie.mp4" loop muted autoplay></video> -->
              </div><!--/.col-->
              <div class="col-lg-6">
                <div class="wrap-text">
                  <h3 class="text-center text-lg-start">ZzzMeterとは...</h3>
                  <p class="desc">スマホで睡眠中の呼吸音を録音して、<br/>
                    「呼吸してないと思われる回数」を<br/>
                    カウントするアプリです。</p>
                </div>
              </div><!--/.col-->
            </div>
          </div><!--/.block-content-->
        </div>
      </section>
      <!-- e: section-easy-to-use -->

      <!-- section-download-app -->
      <section id="download" ref="download" class="section-download-app text-center text-white mb-0">
        <div class="container">
          <div class="sub-text">睡眠時呼吸音測定アプリ ズーメーター</div>
          <div class="logo-zzzmeter">
            <span class="logo-img">
              <img src="images/logo_84x84.png" alt="">
            </span>
            <span class="logo-text">Zzz Meter</span>
          </div>
          <h3 class="sub-text-2 fw-bold">アプリをダウンロード</h3>
          <div class="download-app lg">
            <a href="https://apps.apple.com/jp/app/zzz-meter/id6446402422" target="_blank">
              <img src="images/app-store-lg.png" alt=""/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.zens.nerusoku.app" target="_blank">
              <img src="images/google-play-lg.png" alt=""/>
            </a>
          </div>
          <a href="/contact?tab=form" class="link-download-document rounded-pill is-primary">法人の方はこちらよりお問い合わせ・資料請求</a>
        </div>
      </section>
      <!-- e: section-download-app -->

      <div class="group-section-bg-grey">
        <!--section-steps-->
        <section class="section-steps">
          <div class="container">
            <div class="wrap-1119 mx-auto">
              <div class="heading-line text-end mb-lg-0">
                <h2 class="title-heading-line">3ステップで簡単利用</h2>
              </div><!--/.heading-->
              <div class="row mb-4 mb-md-0 g-0 list-steps justify-content-center">
                <div class="col-md-6 col-lg-4 col-item wow fadeInUp" style="animation-delay: 0.15s;">
                  <div class="card-step">
                    <div class="card-step-number">01</div>
                    <div class="card-step-context">
                      <h3 class="card-step-title">健康状態を入力</h3>
                      <div class="card-step-expert">
                        睡眠に関する質問に<br/>
                        答えていただきます。
                      </div>
                    </div>
                    <div class="card-step-imgs">
                      <div class="group-circles"></div>
                      <img src="images/upload/03-info-question.png" class="img-shadow-1" alt=""/>
                    </div>
                  </div><!--/.card-step-->
                </div><!--/.col-->

                <div class="col-md-6 col-lg-4 col-item wow fadeInUp" style="animation-delay: 0.3s;">
                  <div class="card-step">
                    <div class="card-step-number">02</div>
                    <div class="card-step-context">
                      <h3 class="card-step-title">顔・口の写真を登録</h3>
                      <div class="card-step-expert">
                        顔の正面写真と口の中の<br/>
                        写真を登録いただきます。
                      </div>
                    </div>
                    <div class="card-step-imgs">
                      <div class="group-circles"></div>
                      <img src="images/upload/05-photo-top.png" class="img-shadow-1" alt=""/>
                    </div>
                  </div><!--/.card-step-->
                </div><!--/.col-->

                <div class="col-md-6 col-lg-4 col-item wow fadeInUp" style="animation-delay: 0.45s;">
                  <div class="card-step">
                    <div class="card-step-number">03</div>
                    <div class="card-step-context">
                      <h3 class="card-step-title">呼吸音を録音</h3>
                      <div class="card-step-expert">
                        睡眠中の呼吸音を<br/>
                        録音いただけます。
                      </div>
                    </div>
                    <div class="card-step-imgs">
                      <div class="group-circles"></div>
                      <img src="images/upload/04-recording.png" class="img-shadow-2" alt=""/>
                    </div>
                  </div><!--/.card-step-->
                </div><!--/.col-->
              </div>

              <div class="heading-line text-start d-lg-none">
                <h2 class="title-heading-line">結果表示</h2>
              </div><!--/.heading-->
              <div class="row">
                <div class="col-md-7 text-center text-md-start img-result mb-4 mb-md-0 order-md-last">
                  <img src="images/upload/04-record-result_home.png" class="img-shadow-3" alt=""/>
                </div><!--/.col-->
                <div class="col-md-5">
                  <div class="heading-line text-start mb-0 d-none d-lg-block">
                    <h2 class="title-heading-line">結果表示</h2>
                  </div><!--/.heading-->
                  <div class="result-text">
                    ユーザーがアップロードした録音データから睡眠時の呼吸音を検出し、呼吸音の状態を時系列の円グラフで表示します。録音データの中で呼吸音が聞こえない区間を「呼吸をしていないと思われる区間」と定義し、その回数を表示しています。
                  </div>
                </div><!--/.col-->
              </div>
            </div>
          </div>
        </section>
        <!--e: section-steps-->

        <!-- section-tips -->
        <section id="price" ref="price" class="section-tips">
          <div class="container">
            <div class="heading text-center">
              <h2 class="title-heading">1回だけでなく、<br/>継続的な利用がおすすめ</h2>
              <div class="sub-heading">
                <p>心と体を健康に保つために、睡眠をモニタリングする時代です。</p>
                <p>※課金はアプリ内課金になります。</p>
              </div>
            </div>
            <div class="wrap-1070 mx-auto">
              <div class="row row-lg">
                <div class="col-md-4">
                  <div class="card-tip">
                    <div class="card-tip-text">まずはお試しで<br/>使いたい方</div>
                    <div class="card-tip-btn">
                      <button class="btn btn-custom btn-purle-1 w-100 cursor-auto"><span>1</span>回分を購入するプラン</button>                          
                    </div>
                  </div>
                </div><!--/.col-->

                <div class="col-md-4">
                  <div class="card-tip">
                    <div class="card-tip-text">気になるタイミングで<br/>チェックしたい方</div>
                    <div class="card-tip-btn">
                      <button class="btn btn-custom btn-purle-2 w-100 cursor-auto"><span>3</span>回分を購入するプラン</button>                          
                    </div>
                  </div>
                </div><!--/.col-->

                <div class="col-md-4">
                  <div class="card-tip">
                    <div class="card-tip-text">定期的なチェックで<br/>傾向を把握したい方</div>
                    <div class="card-tip-btn">
                      <button class="btn btn-custom btn-purle-3 w-100 cursor-auto"><span>年間プラン</span>（5回/1ヶ月)</button>
                    </div>
                  </div>
                </div><!--/.col-->
              </div>
            </div>
            <div class="our-plan mx-auto text-center">
              <h3>法人向けプラン</h3>
              <div class="expert fw-bold mb-4">
                法人のお客様向けに従業員様の人数に合わせたプランをご用意しております。<br/>
                詳しくはお問い合わせ・資料請求ページにてご案内します。
              </div>
              <a href="/contact?tab=form" class="btn btn-custom btn-primary-1 rounded-pill">法人の方はこちらよりお問い合わせ・資料請求</a>
            </div><!--/.our-plan-->
          </div>
        </section>
        <!-- e: section-tips -->
      </div>

      <!-- section-download-app -->
      <section class="section-download-app text-center text-white">
        <div class="container">
          <div class="sub-text">睡眠時呼吸音測定アプリ ズーメーター</div>
          <div class="logo-zzzmeter">
            <span class="logo-img">
              <img src="images/logo_84x84.png" alt="">
            </span>
            <span class="logo-text">Zzz Meter</span>
          </div>
          <h3 class="sub-text-2 fw-bold">アプリをダウンロード</h3>
          <div class="download-app lg">
            <a href="https://apps.apple.com/jp/app/zzz-meter/id6446402422" target="_blank">
              <img src="images/app-store-lg.png" alt=""/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.zens.nerusoku.app" target="_blank">
              <img src="images/google-play-lg.png" alt=""/>
            </a>
          </div>
          <a href="/contact?tab=form" class="link-download-document rounded-pill">法人の方はこちらよりお問い合わせ・資料請求</a>
        </div>
      </section>
      <!-- e: section-download-app -->

      <!-- section-faqs -->
      <section id="faqs" ref="faqs" class="section-faqs">
        <div class="container">
          <div class="heading text-center">
            <h2 class="title-heading">よくあるご質問</h2>
          </div>
          <div class="wrap-886 mx-auto">
            <div class="accordion accordion-cs" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading1">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">朝起きたら録音画面がスタート画面に戻っていた</span>
                  </button>
                </h2>
                <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    メモリ不足·ストレージ容量不足でアプリがシャットダウンしてしまったことが考えられます。スマホを一旦再起動していただくと、蓄積されているキャッシュデータが消去されますのでお試しお願いします。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading2">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">いつもスマホを目覚し時計として使用していますが、問題ないですか？</span>
                  </button>
                </h2>
                <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    録音中に目覚ましを使うことは基本的に問題ないです。ただ何らかの原因でアプリが初期画面に戻る場合があります。その場合はメモリ不足、スマホに負荷がかかったことが考えられますので再起動をおすすめします。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading3">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">録音中にスマホを触ってもいいですか？他のアプリやYouTubeを見てもいいですか？</span>
                  </button>
                </h2>
                <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    触っても問題ないですが、操作が終わりましたら録音画面に戻っていただき「再開します」が表示されたら再開をお願いいたします。Androidの機種については録音データがなくなる場合があるため、アプリを開いた状態で録音をお願いいたします。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading4">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">ユーザーの呼吸音以外の音が録音されていても解析できますか？</span>
                  </button>
                </h2>
                <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    ユーザーがアップロードした録音データについては、電話の着信音、救急車のサイレン音、自動車のクラクションなどの生活音を低減した上で、呼吸音を検出します。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading5">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">アップロードがなかなかできません</span>
                  </button>
                </h2>
                <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    WiFi環境が良い所でお試しお願いします。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading6">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">携帯はどの辺に置いて寝るといいですか？</span>
                  </button>
                </h2>
                <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    枕元に置いておやすみください。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading7">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">[アップロード失敗] と出ました。もう一度やり直さないとダメですか？</span>
                  </button>
                </h2>
                <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    アップロードに失敗した場合はメニューを確認いただき「再度アップロード」のボタンが表示されたら再度アップロードをお願いいたします。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading8">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">スマホで録音する際に音量調整は必要ですか？</span>
                  </button>
                </h2>
                <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    ユーザーが購入したときのデフォルト設定のままでアプリをご利用いただけます。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading9">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">睡眠時間は何時間以上が良いですか？</span>
                  </button>
                </h2>
                <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    ６時間以上が理想的ですが、難しい場合はより長く睡眠が取れる日に実施するのをおすすめします。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading10">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">夜電話がかかってきたら出てもいいですか？</span>
                  </button>
                </h2>
                <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    問題ありません。ただ出た場合、録音が停止しますので電話が終わりましたら一度そこまで録音されたデータをアップロードし、再度スタートボタンを押しておやすみください。
                  </div>
                </div>
              </div><!--/.accordion-item-->
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading11">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                    <span class="label-question">Q</span>
                    <span class="txt-question flex-fill">スマホであれば、どのような機種でもアプリを使用できますか？</span>
                  </button>
                </h2>
                <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    どのような機種でもアプリを使用できます。
                  </div>
                </div>
              </div><!--/.accordion-item-->
            </div><!--/.accordion-->
          </div>
        </div>
      </section>
      <!-- e: section-faqs -->

      <!-- section-about-us -->
      <section id="about_us" ref="about_us" class="section-about-us">
        <div class="container">
          <div class="heading text-center">
            <h2 class="title-heading">運営会社のご紹介</h2>
          </div>
          <div class="wrap-520 mx-auto">
            <div class="about-item">
              <div class="about-item-left">会社名</div>
              <div class="about-item-right">株式会社オクチィ</div>
            </div><!--/.about-item-->

            <div class="about-item">
              <div class="about-item-left">所在地</div>
              <div class="about-item-right">
                〒106-0046<br/>
                東京都港区元麻布3-1-6
              </div>
            </div><!--/.about-item-->

            <div class="about-item">
              <div class="about-item-left">代表取締役会長</div>
              <div class="about-item-right">小林 慶一</div>
            </div><!--/.about-item-->

            <div class="about-item">
              <div class="about-item-left">代表取締役社長</div>
              <div class="about-item-right">畑 あゆ美</div>
            </div><!--/.about-item-->

            <div class="about-item">
              <div class="about-item-left">設立</div>
              <div class="about-item-right">2020年2月4日</div>
            </div><!--/.about-item-->

            <div class="about-item">
              <div class="about-item-left">URL</div>
              <div class="about-item-right">https://okuchy.co.jp</div>
            </div><!--/.about-item-->
          </div>
        </div>
      </section>
      <!-- e: section-about-us -->
    </main>
    <!-- e: main-content -->
  <Footer/>
</template>

<script>

import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'zm-home',
  components: {
    Header,
    Footer
  },
}
</script>