<template>
  <router-view/>
</template>

<script>

  export default {
    name: 'App',
    watch: {
      $route (to) {
        window.scrollTo(0, 0)
        var bodyElem = document.getElementsByTagName('body')[0];
        if (to.path == '/thanks') {
          bodyElem.classList.add("bg-body-grey");
        } else {
          bodyElem.classList.remove("bg-body-grey");
        }
        if (to.path == '/') {
          bodyElem.classList.add("home");
        } else {
          bodyElem.classList.remove("home");
        }
      }
    },
  }

</script>
