<template>
  <!-- header -->
    <header class="header-site">
        <nav class="navbar navbar-expand-lg">
            <div class="container">
              <a class="navbar-brand" href="/">
                <span class="brand-img">
                  <img src="images/logo.png" alt="ZzzMeter" />
                </span>
                <span class="brand-text">ZzzMeter</span>
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_3053_5184)">
                    <path d="M4 24H28V21.3333H4V24ZM4 17.3333H28V14.6667H4V17.3333ZM4 8V10.6667H28V8H4Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3053_5184">
                    <rect width="32" height="32" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="text-end">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <g clip-path="url(#clip0_3053_7056)">
                              <path d="M6.57141 8.45764L23.542 25.4282L25.4276 23.5426L8.45703 6.57202L6.57141 8.45764Z" fill="white"/>
                              <path d="M8.45593 25.4281L25.4265 8.45754L23.5409 6.57192L6.57031 23.5425L8.45593 25.4281Z" fill="white"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_3053_7056">
                                <rect width="32" height="32" fill="white"/>
                              </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar-header">
                  <li class="nav-item">
                    <a class="nav-link active jump-to" aria-current="page" target-elem="advertisement" href="javascript:void(0)">ZzzMeterとは</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link jump-to" target-elem="price" href="javascript:void(0)">料金</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link jump-to" target-elem="about_us" href="javascript:void(0)">会社情報</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link jump-to" target-elem="faqs" href="javascript:void(0)">よくある質問</a>
                  </li>
                </ul>
                <!-- <ul class="navbar-language list-unstyled list-inline navbar-header">
                    <li class="list-inline-item"><a href="#">EN</a></li>
                    <li class="list-inline-item"><a href="#">JP</a></li>
                </ul> -->
                <div class="group-btns-header">
                    <a class="btn btn-custom btn-white rounded-pill" href="/contact?tab=form">法人利用の方はこちら</a>
                    <a class="btn btn-custom btn-primary-1 rounded-pill jump-to" target-elem="download" href="javascript:void(0)">今すぐダウンロード</a>
                </div>
              </div>
            </div>
        </nav>
    </header>
    <!-- e: header -->
</template>

<script>
export default {
  name: 'c-header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
